(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/members/assets/javascripts/helpers/sort-fraction-owners.js') >= 0) return;  svs.modules.push('/components/marketplace-data/members/assets/javascripts/helpers/sort-fraction-owners.js');
"use strict";


const {
  roleWeights
} = svs.components.marketplaceData.members.constants;
const sortFractionOwners = members => members.slice().sort((m1, m2) => {
  if (m1.fractions && !m2.fractions) {
    return -1;
  }
  if (m2.fractions && !m1.fractions) {
    return 1;
  }
  if (roleWeights[m1.role] === roleWeights[m2.role]) {
    return m1.name < m2.name ? -1 : 1;
  }
  return roleWeights[m1.role] - roleWeights[m2.role];
});
setGlobal('svs.components.marketplaceData.members.helpers.sortFractionOwners', sortFractionOwners);

 })(window);