(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/members/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/members/assets/javascripts/constants.js');
"use strict";


setGlobal('svs.components.marketplaceData.members.constants', {
  roleWeights: {
    MEMBER: 3,
    ADMIN: 2,
    CAPTAIN: 1,
    TIPSTER: 2,
    NONE: 4,
    SPECTATOR: 4
  }
});

 })(window);