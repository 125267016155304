(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/members/assets/javascripts/members-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/members/assets/javascripts/members-actions.js');
"use strict";


const {
  selectors,
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  CHAT_PURGE_ROOM
} = svs.components.chat.chatData.actions;
const {
  services
} = svs.components.marketplaceData.members;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const {
  roles
} = svs.components.marketplaceData.auth;
const {
  acceptInvite
} = svs.components.marketplaceData.joinTeam.services;
const {
  getErrorMessage
} = svs.components.marketplaceData.errorCodeHandler;
const MEMBER_LEAVE_TEAM = 'MEMBERS/MEMBER_LEAVE_TEAM';
const MEMBER_LEAVE_TEAM_SUCCESS = 'MEMBERS/MEMBER_LEAVE_TEAM_SUCCESS';
const MEMBER_LEAVE_TEAM_FAILURE = 'MEMBERS/MEMBER_LEAVE_TEAM_FAILURE';
const REMOVE_FROM_TEAM = 'MEMBERS/REMOVE_FROM_TEAM';
const REMOVE_FROM_TEAM_SUCCESS = 'MEMBERS/REMOVE_FROM_TEAM_SUCCESS';
const REMOVE_FROM_TEAM_FAILURE = 'MEMBERS/REMOVE_FROM_TEAM_FAILURE';
const TOGGLE_ADMIN = 'MEMBERS/TOGGLE_ADMIN';
const TOGGLE_ADMIN_SUCCESS = 'MEMBERS/TOGGLE_ADMIN_SUCCESS';
const TOGGLE_ADMIN_FAILURE = 'MEMBERS/TOGGLE_ADMIN_FAILURE';
const FOLLOW_GROUP_INIT = 'MEMBERS/FOLLOW_GROUP_INIT';
const FOLLOW_GROUP_SUCCESS = 'MEMBERS/FOLLOW_GROUP_SUCCESS';
const FOLLOW_GROUP_FAILED = 'MEMBERS/FOLLOW_GROUP_FAILED';
const followGroup = (groupId, follow) => (dispatch, getState) => {
  dispatch({
    type: FOLLOW_GROUP_INIT
  });
  let service;
  let serviceArgument;
  const {
    token,
    name
  } = selectors.teams.selectTeam(getState(), {
    id: groupId
  });
  if (follow) {
    service = acceptInvite;
    serviceArgument = token;
  } else {
    service = services.leaveTeam;
    serviceArgument = groupId;
  }
  service(serviceArgument, error => {
    if (error) {
      getErrorMessage(error.code, err => {
        dispatch({
          follow,
          groupId,
          type: FOLLOW_GROUP_FAILED
        });
        dispatch(showDialog({
          title: err.message,
          type: 'error'
        }));
      }, {
        apiMessage: error.message,
        name: "".concat(name),
        specificFollowLimitMessage: true
      });
    } else {
      dispatch({
        type: FOLLOW_GROUP_SUCCESS,
        groupId,
        follow
      });
      const message = follow ? "Du f\xF6ljer nu ".concat(name, ".") : "Du f\xF6ljer inte l\xE4ngre ".concat(name, ".");
      dispatch(showDialog({
        title: message,
        icon: 'checkmark'
      }));
    }
  });
};
const leaveTeamAction = (groupId, callback) => (dispatch, getState) => {
  dispatch({
    type: MEMBER_LEAVE_TEAM,
    groupId
  });

  const {
    Members
  } = getState();
  const member = Members[groupId].filter(person => person.isMe).pop();
  const team = selectors.teams.selectTeam(getState(), {
    id: groupId
  });
  const isParticipatingInGame = Boolean(getState().Games[team.id].data.find(game => (game.fractions[member.id] || 0) > 0));
  services.leaveTeam(groupId, error => {
    if (!error) {
      dispatch(showDialog({
        title: "Du \xE4r inte l\xE4ngre medlem i ".concat(team.name)
      }));
      dispatch({
        type: MEMBER_LEAVE_TEAM_SUCCESS,
        groupId,
        memberId: member.id,
        isParticipatingInGame
      });
      dispatch({
        type: CHAT_PURGE_ROOM,
        roomId: "marketplace_".concat(groupId)
      });
      callback(null);
    } else {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch(showDialog({
          title: err.message,
          type: 'error'
        }));
        dispatch({
          type: MEMBER_LEAVE_TEAM_FAILURE,
          groupId,
          error
        });
        callback(error);
      }, {
        defaultMessage: 'Det gick inte att lämna laget, försök om en stund igen.'
      });
    }
  });
};
const toggleAdminAction = (groupId, memberId, callback) => (dispatch, getState) => {
  const {
    Members
  } = getState();
  const team = selectors.teams.selectTeam(getState(), {
    id: groupId
  });
  const member = Members[groupId].filter(member => member.id === memberId)[0];
  const isCurrentlyAdmin = member.role === roles.ROLES.ADMIN;
  dispatch({
    type: TOGGLE_ADMIN,
    groupId,
    memberId,
    isCurrentlyAdmin
  });
  services.toggleAdmin(groupId, member, !isCurrentlyAdmin, error => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: TOGGLE_ADMIN_FAILURE,
          groupId,
          memberId,
          isCurrentlyAdmin,
          error
        });
        dispatch(showDialog({
          title: err.message,
          type: 'error'
        }));
        callback(error);
      }, {
        defaultMessage: resolveDefaultErrorMessage(isCurrentlyAdmin, member, team)
      });
    } else {
      if (!isCurrentlyAdmin) {
        dispatch(showDialog({
          title: "".concat(member.name, " har gjorts till speladmin i ").concat(team.name, "."),
          icon: 'checkmark'
        }));
      } else {
        dispatch(showDialog({
          title: "".concat(member.name, " har tagits bort som speladmin i ").concat(team.name, "."),
          icon: 'checkmark'
        }));
      }
      dispatch({
        type: TOGGLE_ADMIN_SUCCESS,
        groupId,
        memberId,
        isCurrentlyAdmin
      });
      callback();
    }
  });
};
const resolveDefaultErrorMessage = (isCurrentlyAdmin, member, team) => {
  if (isCurrentlyAdmin) {
    return "Det gick inte att ta bort ".concat(member.name, " som speladmin i ").concat(team.name, ". F\xF6rs\xF6k igen om en stund.");
  }
  return "Det gick inte att g\xF6ra ".concat(member.name, " till speladmin i ").concat(team.name, ". F\xF6rs\xF6k igen om en stund.");
};
const removeFromTeamAction = (groupId, memberId, callback) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_FROM_TEAM,
    groupId,
    memberId
  });
  const {
    Members
  } = getState();
  const team = selectors.teams.selectTeam(getState(), {
    id: groupId
  });
  const member = Members[groupId].filter(member => member.id === memberId)[0];
  services.removeFromTeam(groupId, member, error => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: REMOVE_FROM_TEAM_FAILURE,
          groupId,
          memberId,
          error
        });
        dispatch(showDialog({
          title: err.message,
          type: 'error'
        }));
        callback(error);
      }, {
        defaultMessage: "Det gick inte att ta bort ".concat(member.name, " fr\xE5n ").concat(team.name, ". F\xF6rs\xF6k igen om en stund.")
      });
    } else {
      dispatch(showDialog({
        title: "".concat(member.name, " har tagits bort fr\xE5n ").concat(team.name, "."),
        icon: 'checkmark'
      }));
      dispatch({
        type: REMOVE_FROM_TEAM_SUCCESS,
        groupId,
        memberId
      });
      callback();
    }
  });
};
setGlobal('svs.components.marketplaceData.members.actions', {
  MEMBER_LEAVE_TEAM,
  MEMBER_LEAVE_TEAM_FAILURE,
  MEMBER_LEAVE_TEAM_SUCCESS,
  leaveTeamAction,
  REMOVE_FROM_TEAM,
  REMOVE_FROM_TEAM_FAILURE,
  REMOVE_FROM_TEAM_SUCCESS,
  removeFromTeamAction,
  TOGGLE_ADMIN,
  TOGGLE_ADMIN_FAILURE,
  TOGGLE_ADMIN_SUCCESS,
  toggleAdminAction,
  FOLLOW_GROUP_INIT,
  FOLLOW_GROUP_SUCCESS,
  FOLLOW_GROUP_FAILED,
  followGroup
});

 })(window);