(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/members/assets/javascripts/services/remove-from-team.js') >= 0) return;  svs.modules.push('/components/marketplace-data/members/assets/javascripts/services/remove-from-team.js');
"use strict";


const {
  jupiter
} = svs.core;
const removeFromTeam = (groupId, member, callback) => {
  const url = "/marketplace/1/groups/".concat(groupId, "/members");
  jupiter.put({
    path: url,
    data: {
      customerId: member.id,
      removeMember: true
    }
  }, () => {
    callback();
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.members.services.removeFromTeam', removeFromTeam);

 })(window);