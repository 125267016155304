(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/members/assets/javascripts/services/toggle-admin.js') >= 0) return;  svs.modules.push('/components/marketplace-data/members/assets/javascripts/services/toggle-admin.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  roles
} = svs.components.marketplaceData.auth;
const toggleAdmin = (groupId, member, state, callback) => {
  if (typeof groupId !== 'number') {
    throw new Error("Expected groupId to be a number. Received ".concat(groupId));
  }
  if (typeof member !== 'object') {
    throw new Error("Expected member to be an object with an member. Received ".concat(member));
  }
  if (typeof member.id !== 'number') {
    throw new Error("Expected member.id to be a number. Received ".concat(member.id));
  }
  if (typeof state !== 'boolean') {
    throw new Error("Expected state to be a boolean. Received ".concat(state));
  }
  if (typeof callback !== 'function') {
    throw new Error("Expected callback to be a function. Received ".concat(callback));
  }
  const url = "/marketplace/1/groups/".concat(groupId, "/members");
  jupiter.put({
    path: url,
    data: {
      customerId: member.id,
      memberRole: state ? roles.getId(roles.ROLES.ADMIN) : roles.getId(roles.ROLES.MEMBER),
      removeMember: false
    }
  }, () => {
    callback();
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.members.services.toggleAdmin', toggleAdmin);

 })(window);